import { useLazyQuery } from "@apollo/client"
import { DataGrid, Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2"
import { Skeleton } from "@mui/material"
import { useEffect, useState } from "react"
import styled from "styled-components"

import {
  CardDelivery,
  CardDeliveryData,
  DeliveryAddress,
  GET_CARD_DELIVERIES,
  LogisticsPartnerEnum,
} from "../../../../api/queries/get-card-deliveries"

import {
  GridPagination,
  RefetchProps,
} from "../../../../components/GridPagination/GridPagination"
import dispatchToast from "../../../../utils/dispatchToast"
import { capitalizeWords } from "../../../../utils/helpers"
import { ModalTableContainer } from "../styles"
import { CpfInconsistencyModal } from "./CpfInconsistencyModal"

export const CardDeliveriesTable = ({
  externalCardOrderId,
}: {
  externalCardOrderId: string;
}) => {
  const pageSize = 10
  const initialFetchConfigState = {
    externalCardOrderId,
    limit: pageSize,
    skip: 0,
  }
  const [selectedPage, setSelectedPage] = useState(1)
  const [fetchConfigState, setFetchConfigState] = useState(
    initialFetchConfigState,
  )
  const [selectedCardDelivery, setSelectedCardDelivery] =
    useState<CardDelivery>()
  const [isCpfInconsistencyModalVisible, setIsCpfInconsistencyModalVisible] =
    useState(false)

  const [getCardDeliveries, { data, loading: loadingGetCardDeliveries }] =
    useLazyQuery(GET_CARD_DELIVERIES, {
      fetchPolicy: "network-only",
      variables: { ...initialFetchConfigState },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os dados das entregas.",
        })
      },
    })

  const refetchWithPagination = ({ pageIndex, pageSize }: RefetchProps) => {
    const limit = pageSize
    const skip = (pageIndex - 1) * pageSize

    setFetchConfigState((previousState) => ({
      ...previousState,
      limit,
      skip,
    }))
  }

  useEffect(() => {
    getCardDeliveries({
      variables: fetchConfigState,
    })
  }, [fetchConfigState])

  const onPageClick = (page: number) => {
    setSelectedPage(page)
    refetchWithPagination({ pageIndex: page, pageSize })
  }

  const builtTableData = (data: CardDeliveryData) => {
    if (data?.getCardDeliveries?.cardDeliveries.length > 0) {
      const {
        getCardDeliveries: { cardDeliveries },
      } = data
      return cardDeliveries.map((cardDelivery: CardDelivery) => ({
        recipientName: capitalizeWords(cardDelivery.recipient.name),
        cardAmount: cardDelivery.cardAmount,
        status: cardDelivery.status,
        address: formatDeliveryAddress(cardDelivery.deliveryAddress),
        trackingCode: buildTrackingUrl(
          cardDelivery.trackingCode,
          cardDelivery.logisticsPartner,
        ),
        externalCardDeliveryId: cardDelivery.externalCardDeliveryId,
        cardDeliveryInconsistency: renderAlerts(cardDelivery),
      }))
    }
    return []
  }

  const renderAlerts = (cardDelivery: CardDelivery) => {
    const tooltipTexts: string[] = []

    if (cardDelivery?.deliveryInconsistency?.cpf) {
      tooltipTexts.push(
        "CPF do colaborador não foi encontrado. Cadastre-o na plataforma ou informe outro CPF clicando aqui.",
      )
    }

    if (tooltipTexts.length > 0) {
      return (
        <Tooltip title={tooltipTexts.join("\n")}>
          <StyledDiv>
            <Icons
              name="IconAlertCircle"
              fill="transparent"
              color="orange"
              size={40}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedCardDelivery(cardDelivery)
                setIsCpfInconsistencyModalVisible(true)
              }}
            />
          </StyledDiv>
        </Tooltip>
      )
    }
    return (
      <Icons
        name="IconCircleCheck"
        fill="transparent"
        color="green"
        size={40}
      />
    )
  }

  return (
    <div>
      <ModalTableContainer>
        {loadingGetCardDeliveries ? (
          <Skeleton
            variant="rectangular"
            style={{
              marginBottom: "40px",
              borderRadius: "20px",
              maxHeight: "828px",
              display: "flex",
              flexGrow: 1,
            }}
          />
        ) : (
          <DataGrid
            columns={columns}
            data={builtTableData(data)}
            pageCount={data?.getCardDeliveries?.totalCardDeliveries / pageSize}
            hasPagination={true}
            customPagination={() => (
              <GridPagination
                rowsPerPage={pageSize}
                selectedPage={selectedPage}
                onPageClick={onPageClick}
                // isLoading={loadingGetCardDeliveries}
                isLoading={false}
                totalItems={data?.getCardDeliveries?.totalCardDeliveries}
                showRowsPerPageOptions={false}
              />
            )}
          />
        )}
      </ModalTableContainer>
      <CpfInconsistencyModal
        open={isCpfInconsistencyModalVisible}
        onClose={() => {
          setIsCpfInconsistencyModalVisible(false)
          getCardDeliveries()
        }}
        deliveryInconsistencies={selectedCardDelivery?.deliveryInconsistency}
        externalCardDeliveryId={selectedCardDelivery?.externalCardDeliveryId}
      />
    </div>
  )
}

export const formatDeliveryAddress = (deliveryAddress: DeliveryAddress) => {
  return `
    ${deliveryAddress.address}, ${deliveryAddress.addressNumber}${
      deliveryAddress.complement && `, ${deliveryAddress.complement}`
    } - ${deliveryAddress.neighborhood}, ${deliveryAddress.city} - ${
      deliveryAddress.state
    }, ${deliveryAddress.zipCode}
  `
}

export const buildTrackingUrl = (
  trackingCode: string,
  logisticsPartner?: LogisticsPartnerEnum,
) => {
  if (!trackingCode) return "-"

  if (logisticsPartner) {
    if (logisticsPartner === LogisticsPartnerEnum.tex) {
      return (
        <a
          href={"https://totalconecta.totalexpress.com.br/rastreamento/"}
          target="_blank"
          rel="noreferrer"
        >
          {trackingCode}
        </a>
      )
    }

    return (
      <a
        href={`https://sigasuaencomenda.com.br/rastreamento/simples/flashapp?npc=${trackingCode}`}
        target="_blank"
        rel="noreferrer"
      >
        {trackingCode}
      </a>
    )
  }

  return "-"
}

const StyledDiv = styled.div``

const StyledTableCell = styled.p`
  font-size: 12px;
  font-weight: 700;
`

const columns = [
  {
    Header: "Código da remessa",
    accessor: "externalCardDeliveryId",
    disableSortBy: true,
    Cell: ({ value }: any) => <StyledTableCell>{value}</StyledTableCell>,
  },
  {
    Header: "Colaborador",
    accessor: "recipientName",
    disableSortBy: true,
    Cell: ({ value }: any) => <StyledTableCell>{value}</StyledTableCell>,
  },
  {
    Header: "Quantidade",
    accessor: "cardAmount",
    disableSortBy: true,
    Cell: ({ value }: any) => (
      <StyledTableCell style={{ textAlign: "center" }}>{value}</StyledTableCell>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    disableSortBy: true,
    Cell: ({ value }: any) => <StyledTableCell>{value}</StyledTableCell>,
  },
  {
    Header: "Endereço",
    accessor: "address",
    disableSortBy: true,
    minWidth: 285,
    Cell: ({ value }: any) => <StyledTableCell>{value}</StyledTableCell>,
  },
  {
    Header: "Código de rastreio",
    accessor: "trackingCode",
    disableSortBy: true,
    Cell: ({ value }: any) => (
      <StyledTableCell style={{ textAlign: "center" }}>{value}</StyledTableCell>
    ),
  },
  {
    Header: "Alertas",
    accessor: "cardDeliveryInconsistency",
    disableSortBy: true,
  },
]
